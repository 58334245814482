@import '../../../../styles/palette.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/typography.scss';

.CompTable {
  border: 1px solid $Outline;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 8px;
  .comp-table-wrapper {
    position: relative;
    overflow-y: scroll;
    max-height: 528px;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 100px;
      background-color: $DarkOutline;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-corner {
      background: rgb(0 0 0 / 0%);
    }
    .comp-table-rows-wrapper {
      overflow-x: scroll;
      overflow-y: visible;
      margin-left: 336px;
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 100px;
        background-color: $DarkOutline;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-corner {
        background: rgb(0 0 0 / 0%);
      }
    }
  }
  .table-footer {
    background-color: $MidnightSteel;
    @include flexbox(flex-end);
    .MuiButtonBase-root {
      @include flexbox(flex-start, center);
      border-right: 1px solid $Outline;
      border-left: 1px solid $Outline;
      border-radius: 0;
      text-transform: none;
      color: $Primary80;
      margin-right: 20px;
      .MuiSvgIcon-root {
        font-size: 20px;
        margin-right: 2px;
      }
    }
  }
}

.active-comp-confirmation-modal {
  .MuiPaper-root {
    overflow: hidden;
    min-width: 686px;
    border-radius: 24px;
    padding: 56px;
    .dialog-header {
      @include flexbox(flex-start, center);
      gap: 8px;
      @include headline-medium;
      color: $LightError;
      .MuiSvgIcon-root {
        width: 36px;
        height: 36px;
      }
      margin-bottom: 24px;
    }
    p {
      @include body-large;
      margin-bottom: 24px;
      &:first-of-type {
        font-weight: 500;
      }
    }
    .bottom-btns {
      @include flexbox(flex-end, center);
      .MuiButtonBase-root {
        @include blue-contained-button;
        &:first-child {
          @include outlined-button;
          margin-right: 16px;
        }
      }
    }
  }
}

.bottom-arrow-tooltip {
  &.select-deselect {
    inset: auto auto 2px 0px !important;
    .MuiTooltip-tooltip {
      text-align: center;
    }
    &.top-start {
      inset: auto auto 2px -4px !important;
      .MuiTooltip-arrow {
        margin-left: 2px;
      }
    }
  }
}
