@import '../../../../styles/palette.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/typography.scss';

.CompTableHeader {
  background-color: $MidnightSteel;
  position: relative;
  border-top-left-radius: 4px;
  .fixed-left {
    position: absolute;
    overflow: hidden;
    box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);
  }
  .right-scrollable {
    margin-left: 336px;
    overflow-x: hidden;
  }
  .table-header-top-titles {
    height: 32px;
    @include flexbox(flex-start, center);
    gap: 14px;
    div {
      border-bottom: 1px solid $Outline;
      margin-top: 4px;
      h6 {
        @include label-large;
        color: $DarkOnSecondaryContainer;
        margin-bottom: 2px;
        margin-left: 16px;
      }
      &.comp-list {
        min-width: 320px;
      }
      &.market-cap {
        min-width: 550px;
      }
      &.market-growth {
        min-width: 160px;
      }
    }
  }
  .table-sub-headers {
    @include flexbox(flex-start, center);
    height: 48px;
    .sub-header {
      @include flexbox(center, center);
      .chevron-icon {
        color: $DarkOnSecondaryContainer;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        margin: 0 2px;
        .MuiSvgIcon-root {
          height: 16px;
          width: 16px;
        }
        &:hover {
          background-color: #434f68;
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        &.hidden {
          visibility: hidden;
        }
        &.active {
          visibility: visible;
        }
      }
      .sub-header-title {
        @include flexbox(center, center, column);
        gap: 6px;
        padding-left: 24px;
        h6,
        span {
          @include label-medium;
          text-align: center;
        }
        h6 {
          margin: 0;
          color: $DarkOnSecondaryContainer;
        }
        span {
          color: $Secondary70;
        }
      }
    }
    .sub-header-group {
      @include flexbox(flex-start, center);
      &.comp-list {
        position: relative;
        min-width: 210px;
        h6 {
          margin-left: 32px;
        }
        .MuiCheckbox-root {
          position: absolute;
          top: 20px;
          left: 6px;
          padding: 0;
          .unchecked-icon {
            width: 16px;
            height: 16px;
            border: 1px solid $White30;
            border-radius: 2px;
          }
          .checked-icon {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            background-color: $InitioBlue;
            @include flexbox(center, center);
            .MuiSvgIcon-root {
              color: #fafafd;
              padding: 6px;
            }
          }
        }
        .sub-header {
          .chevron-icon {
            margin-bottom: 22px;
          }
        }
      }
      &.basic-info {
        .sub-header {
          &:nth-child(1) {
            min-width: 106px;
          }
          &:nth-child(2) {
            min-width: 136px;
          }
          &:nth-child(3) {
            min-width: 144px;
          }
          &:nth-child(4) {
            min-width: 130px;
          }
          &:nth-child(5) {
            min-width: 130px;
          }
          &:nth-child(6),
          &:nth-child(7) {
            min-width: 114px;
            .sub-header-title {
              padding-left: 0;
            }
          }
        }
      }
      &.val-volatility,
      &.tr-volatility {
        .sub-header {
          min-width: 110px;
        }
      }
      &.market-cap {
        .sub-header {
          &:nth-child(1) {
            min-width: 172px;
          }
          &:nth-child(2) {
            min-width: 186px;
          }
          &:nth-child(3) {
            min-width: 200px;
          }
        }
      }
      &.market-growth {
        .sub-header {
          min-width: 160px;
        }
      }
      &.revenue,
      &.EBITDA,
      &.gross-profit,
      &.revenue-growth,
      &.EBITDA-growth,
      &.gross-profit-growth,
      &.revenue-multiples,
      &.EBITDA-multiples,
      &.EBITDA-margin,
      &.gross-margin {
        .sub-header {
          min-width: 166px;
        }
      }
    }
  }
}
