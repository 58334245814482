@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.no-report-to-show {
  @include flexbox(center, center, column);
  .header-info-block {
    border-radius: 8px;
    background-color: $LightSurfaceVariant;
    box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
    padding: 32px 20px;
    padding-right: 650px;
    margin-bottom: 16px;
    width: 1334px;
    h4 {
      margin: 0;
      .MuiSvgIcon-root {
        font-size: 48px;
        margin-right: 8px;
      }
      @include display-small('Manrope');
    }
    p,
    ul {
      @include body-small;
      color: $LightOutline;
      margin: 0;
      span {
        font-weight: 600;
      }
    }
    p {
      &:first-of-type {
        margin-top: 12px;
      }
    }
  }
  .no-report-text {
    @include flexbox(center, center);
    width: 1334px;
    height: 104px;
    border: 1px solid $LightOutline;
    border-radius: 12px;
    @include label-medium;
    background-color: #cfdcec;
  }
}

.PDFReport {
  margin-top: 24px;
  .tooltip-info-block {
    width: 1124px;
    margin: auto;
    @include flexbox(flex-start, center);
    gap: 8px;
    border: 1px solid $InitioBlue;
    padding: 6px 12px;
    background-color: #cfd9ea;
    margin-bottom: 16px;
    border-radius: 12px;
    p {
      @include body-small;
      margin: 0;
    }
    .MuiSvgIcon-root {
      color: $InitioBlue;
    }
    .close-icon {
      margin-left: auto;
      .MuiSvgIcon-root {
        color: $LightOnSurfaceVariant;
      }
    }
  }
  .top-btns {
    width: 1480px;
    @include flexbox();
    gap: 16px;
    margin: auto;
    .switch {
      position: relative;
      .MuiSwitch-root {
        width: 220px !important;
        height: 32px !important;
        padding: 0 !important;
        border-radius: 20px;
        .MuiSwitch-track {
          width: 214px;
          height: 32px;
          border-radius: 20px;
          background-color: #bac7db !important;
          box-shadow: 0px 4px 8px 0px rgba(18, 29, 33, 0.12) inset;
        }
        .MuiButtonBase-root {
          padding: 0;
          height: 32px;
          .MuiSwitch-thumb {
            height: 33px;
            width: 100px;
            border-radius: 18px;
            margin-left: -2px;
            box-shadow: none;
            background-color: $Container2;
            box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
          }
          &.Mui-checked {
            transform: translateX(98px);
            .MuiSwitch-thumb {
              height: 33px;
              width: 120px;
              border-radius: 18px;
              box-shadow: none;
            }
          }
          .PrivateSwitchBase-input {
            padding: 0;
            height: 32px;
            border-radius: 20px;
          }
        }
      }
      .labels {
        position: absolute;
        top: 6px;
        left: 18px;
        pointer-events: none;
        @include flexbox(center, center);
        gap: 34px;
        span {
          @include body-medium;
          color: $Primary40;
          &.selected {
            @include label-large;
          }
          line-height: 0;
        }
      }
    }
    .generate-report-btns {
      margin-left: auto;
      .MuiButtonBase-root {
        @include outlined-button;
        height: 32px;
        &:last-child {
          @include blue-contained-button;
          margin-left: 16px;
        }
      }
    }
    margin-bottom: 24px;
  }

  .spacer {
    height: 32px;
    width: 100%;
    background-color: $MidnightSteel;
  }

  .edit-notes-header {
    @include label-large;
    text-align: center;
    margin: 24px 0;
  }

  .pdf-list-wrapper {
    width: 100%;
    .view-toggle-btn {
      @include flexbox(flex-end, center);
      background-color: $MidnightSteel;
      .MuiButtonBase-root {
        @include text-only-button;
        height: 32px;
        color: $Primary80;
      }
    }
    .pdf-list {
      @include flexbox(flex-start, center);
      gap: 48px;
      background-color: $BlackSteel;
      padding: 16px 24px 4px;
      overflow-x: scroll;
      position: relative;
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 100px;
        background-color: $DarkOutline;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-corner {
        background: rgb(0 0 0 / 0%);
      }
      .pdf-tile {
        @include flexbox(flex-start, center, column);
        gap: 8px;
        img {
          width: 144px;
          height: 93px;
          outline: 1px solid $DarkOutline;
        }
        .thumbnail-overlay {
          position: absolute;
          width: 144px;
          height: 93px;
          @include flexbox(center, center);
          gap: 16px;
          background-color: rgba(255, 255, 255, 0.2);
          .MuiIconButton-root {
            color: $DarkOnSurfaceVariant;
            border-radius: 50%;
            height: 32px;
            width: 32px;
            padding: 2px;
            &:hover {
              background-color: rgba(234, 241, 255, 0.1);
            }
            &:active {
              background-color: rgba(234, 241, 255, 0.4);
            }
            &:focus-visible {
              outline: 2px solid $Primary80;
            }
          }
        }
        p {
          @include label-small;
          color: $DarkOnSecondaryContainer;
        }
        &:focus-visible,
        &.report-in-view {
          img {
            outline: 2px solid $InitioBlue;
          }
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
      }
    }
  }

  .page-list-and-report-viewer-wrapper {
    @include flexbox;
    position: relative;
    .page-list {
      h6 {
        @include label-large;
        margin: 16px 0px;
      }
      padding: 0px 16px 124px;
      height: 916px;
      min-width: 200px;
      overflow: hidden;
      padding-right: 15px;
      &:hover {
        overflow-y: scroll;
        padding-right: 0px;
      }
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 100px;
        background-color: $DarkOutline;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-corner {
        background: rgb(0 0 0 / 0%);
      }
      border-right: 1px solid $DarkOutline;
      @include flexbox(flex-start, center, column);
      .page-thumbnail {
        width: 146px;
        height: 95px;
        box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);
        border: 1px solid transparent;
        opacity: 0.7;
        &.page-in-view {
          opacity: 1;
          outline: 2px solid $InitioBlue;
        }
        &:hover,
        &:focus-visible {
          opacity: 0.9;
          box-shadow: 0px 8px 16px 0px rgba(0, 49, 93, 0.2);
        }
        &:focus-visible {
          outline: 1px solid $InitioBlue;
        }
      }
      p {
        @include label-small;
        margin-top: 8px;
        margin-bottom: 16px;
        text-align: center;
      }
    }
  }

  .report-viewer-wrapper {
    width: 100%;
    .report-viewer {
      margin: auto;
      height: 946px;
      width: 1100px;
      overflow-y: scroll;
      padding-bottom: 180px;
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        margin-top: 24px;
        margin-bottom: 164px;
      }
      &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 100px;
        background-color: $DarkOutline;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-corner {
        background: rgb(0 0 0 / 0%);
      }
      .report-title {
        @include label-large;
        margin: 16px 0;
        text-align: center;
      }
      .react-pdf__Document {
        @include flexbox(flex-start, center, column);
        gap: 24px;
      }
      .loading-pdf {
        margin-top: 64px;
        text-align: center;
        @include title-small;
        p {
          margin-top: 16px;
        }
      }
    }
  }
  .report-footer {
    width: 100%;
    height: 164px;
    position: absolute;
    bottom: 0;
    background-color: rgba(50, 55, 75, 0.95);
    @include flexbox(center, center);
    .right-btns {
      margin-left: auto;
      margin-right: 64px;
      .MuiButtonBase-root {
        margin-right: 16px;
        height: 32px;
        @include outlined-button-dark;
        &:not(:disabled) {
          &:not(:last-child) {
            .MuiSvgIcon-root {
              color: $Primary50;
            }
          }
        }
        &:last-child {
          @include blue-contained-button-dark;
        }
      }
    }
  }
}

.generating-pdf-dialog {
  .MuiPaper-root {
    padding: 64px;
    @include flexbox(center, center, column);
    gap: 16px;
    p {
      @include label-large;
    }
  }
}

.footnotes-wrapper {
  width: 1344px;
  background-color: $Container2;
  padding: 24px 0;
  margin: auto;
  .edit-footnotes {
    margin: auto;
    background-color: $NeutralVariant95;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.75rem;
    gap: 1.5rem;
    width: 1264px;

    .MuiInputBase-root {
      background-color: $Container2;
      width: 38rem;
      min-height: 5.5rem;
      font-size: 14px;
      min-height: 84px;
      background-color: white;
      .MuiInputBase-input {
        margin-bottom: auto;
      }
      textarea::-webkit-scrollbar {
        display: none;
      }
      .MuiInputAdornment-root {
        margin-top: auto;
        margin-bottom: 10px;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }

    .revert-btn {
      @include text-only-button;
      color: $Primary30;
      display: flex;
      margin-left: auto;
      padding-right: 0.75rem;
      font-weight: 400;
      .MuiSvgIcon-root {
        font-size: 1.25rem;
        margin-left: 0.5rem;
        font-weight: 600;
        transform: scaleX(-1);
      }
    }

    .footnote-characters-left {
      @include body-small;
      text-align: right;
      padding-right: 0.35rem;
    }

    .calc-page-header {
      @include flexbox(space-between, center, row);
      grid-column: span 2;
      .MuiSvgIcon-root {
        font-size: 1.5rem;
        margin-right: 0.5rem;
        margin-bottom: auto;
      }
      .header-text {
        margin-right: auto;
        h4 {
          @include label-large;
          white-space: nowrap;
        }
        p {
          @include label-large;
          font-weight: 400;
        }
      }
      .MuiButtonBase-root {
        @include text-only-button;
        white-space: nowrap;
      }
    }

    hr {
      display: grid;
      width: 100%;
      grid-column: span 2;
      margin: 0rem;
    }

    .footnote {
      p {
        @include label-large;
        margin-bottom: 0.75rem;
        .MuiSvgIcon-root {
          font-size: 1.5rem;
          margin-right: 0.75rem;
        }
      }
      .sub-header {
        @include label-large;
        color: #79747e;
        margin-bottom: 0.75rem;
      }
      .MuiInputBase-root {
        margin-bottom: 1rem;
        margin-top: 0.5rem;
      }
      &.span-two {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
        p {
          grid-column: span 2;
          margin-bottom: 0rem;
        }
        grid-column: span 2;
      }
      &.general-ac {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column: span 2;
        gap: 1.5rem;
        p {
          grid-column: span 2;
          span {
            @include body-medium;
          }
        }
        .MuiInputBase-root {
          min-height: 3rem;
          margin-bottom: 0.56rem;
        }
      }
    }

    .weighting-blocks {
      display: grid;
      grid-column: span 2;
      grid-template-columns: 1fr 1fr;
      .block-header {
        @include label-large;
        display: grid;
        grid-column: 1 / 3;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 1.5rem;
        .MuiInputBase-root {
          width: 38rem;
          margin-bottom: 1rem;
        }
        .MuiSvgIcon-root {
          font-size: 1.5rem;
          margin-right: 0.75rem;
        }
      }
      .weight-block {
        @include flexbox(flex-start, flex-start, row);
        .fn-input {
          @include flexbox(flex-start, flex-start, column);
          p {
            @include label-large;
            color: $LightOutline;
            margin-bottom: 0.5rem;
          }
          .MuiInputBase-root {
            width: 30rem;
            margin-bottom: 0.25rem;
          }
        }
        .weight-container {
          @include flexbox(center, center, column);
          margin-left: 1.5rem;
          p {
            @include label-large;
            margin-bottom: 0.5rem;
          }
          div {
            @include flexbox(center, center, column);
            height: 2.5rem;
            width: 5.5rem;
            border-radius: 0.5rem;
            border: 2px solid #bdc2ff;
            background: #f1efff;
            span {
              @include label-large;
              color: $Tertiary40;
            }
            h6 {
              @include label-medium;
              font-size: 0.5625rem;
              color: $Tertiary40;
              margin-bottom: 0rem;
            }
          }
        }
        .footnote-characters-left {
          width: 30rem;
        }
      }
    }

    .methodology-summary {
      display: grid;
      grid-column: span 2;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
      h6 {
        @include label-large;
        grid-column: span 2;
        margin-bottom: 0rem !important;
        .MuiSvgIcon-root {
          font-size: 1.5rem;
          margin-right: 0.75rem;
        }
      }
      .gpc-criteria {
        display: grid;
        grid-column: span 2;
        div {
          p {
            @include label-large;
            color: $LightOutline;
            margin-bottom: 0.5rem;
          }
          .footnote-characters-left {
            margin-right: 1.1rem;
            margin-top: 0.25rem;
          }
          &:nth-child(1) {
            grid-column: 1 / 2;
            .revert-btn {
              margin-top: 0.56rem;
            }
          }
          &:nth-child(2) {
            grid-column: 2 / 2;
            .revert-btn {
              margin-top: 0.56rem;
            }
          }
        }
      }
      .column {
        h5 {
          @include label-large;
          margin-bottom: 1.25rem;
        }
        p {
          @include label-large;
          color: #79747e;
          margin-bottom: 0.5rem;
          span {
            font-weight: 400;
          }
          &:not(:first-child) {
            margin-top: 1.25rem;
          }
        }
        .info-card {
          @include label-large;
          border-radius: 0.5rem;
          border: 2px solid #bdc2ff;
          background: #f1efff;
          height: 3.5rem;
          color: $Tertiary40;
          padding: 1rem 0.75rem;
          &.right {
            width: 15.375rem;
          }
          &:not(:first-child) {
            margin-top: 1.25rem;
          }
        }
        &.right {
          .value-and-ac {
            @include flexbox(flex-start, flex-start, column);
            .card-input {
              @include flexbox(flex-start, flex-start, row);
              gap: 1rem;
            }
            p {
              margin-bottom: 0.5rem;
              .far-right-label {
                &.valuation {
                  margin-left: 6.05rem;
                }
                &.allocation {
                  margin-left: 10.75rem;
                }
              }
            }
            .MuiFormControl-root {
              margin-top: 1.75rem;
              .MuiInputBase-root {
                width: 19.75rem;
              }
              &:not(:first-child) {
                margin-top: 0rem;
              }
            }
            .MuiButtonBase-root {
              margin-right: 1rem;
            }
            .revert-btn {
              margin-top: 0.56rem;
              margin-bottom: 0.56rem;
            }
          }
          .ac {
            .MuiInputBase-root {
              width: 36rem;
            }
          }
          &.gpc {
            border-top: 1px solid #999ba5;
            h6 {
              margin-top: 1.12rem;
              margin-bottom: 1rem !important;
            }
            .value-and-ac {
              .card-input {
                .info-card {
                  width: 17.75rem;
                }
                .MuiInputBase-root {
                  width: 18.25rem;
                }
              }
            }
          }
          .revert-btn {
            margin-top: 0.56rem;
          }
        }
        &.left {
          padding-right: 1.5rem;
          border-right: 1px solid #999ba5;
          &.gpc {
            border-top: 1px solid #999ba5;
            border-right: none;
            h6 {
              margin-top: 1.12rem;
            }
            .gpc-two-card {
              @include flexbox(flex-start, flex-start, row);
              margin-top: 1rem;
              gap: 1rem;
              .info-card {
                width: 17.75rem;
                margin-top: 0rem;
              }
              .MuiInputBase-root {
                width: 18.25rem;
              }
            }
          }
          .revert-btn {
            margin-top: 0.56rem;
          }
        }
      }
    }
  }
}

.confirm-deletion, 
.confirm-send-email {
  .MuiPaper-root {
    padding: 34px;
    border-radius: 24px;
    width: 686px;
    h4 {
      @include flexbox(flex-start, center);
      @include headline-medium;
      margin-bottom: 16px;
      .MuiSvgIcon-root {
        font-size: 36px;
        margin-right: 8px;
      }
    }
    p {
      @include body-large;
      margin-bottom: 24px;
    }
    .bottom-btns {
      @include flexbox(center, center);
      margin-left: auto;
      .MuiButtonBase-root {
        @include outlined-button;
        &:last-child {
          @include red-contained-button;
          margin-left: 16px;
        }
      }
    }
  }
}

.confirm-deletion {
  .MuiPaper-root {
    h4 {
      color: $LightError;
    }
    .bottom-btns {
      .MuiButtonBase-root {
        &:last-child {
          @include red-contained-button;
        }
      }
    }
  }
}
