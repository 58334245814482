@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';


.Holdings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  
  .header-info-block,
  .industries-select-wrapper,
  .comps-wrapper {
    border-radius: 8px;
    background-color: $LightSurfaceVariant;
    box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
  }
  
  .page-info {
    @include flexbox(space-between, center, row);
    width: 79rem;
    height: 56px;
    margin-bottom: 20px;
    background-color: #00488414;
    border: 1px solid #4295F3;
    border-radius: 0.75rem;
  
    p {
      @include body-small;
      padding-left: 1.5rem;
      .MuiSvgIcon-root {
        margin-right: 0.5rem;
        color: #4295F3;
      }
    }
    .MuiButtonBase-root {
      @include text-only-button;
      margin-right: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      padding: 16px;
      .MuiSvgIcon-root {
        color: $LightOnSurfaceVariant;
        margin: 0px;
      }
    }
    &.active {
      display: none;
    }
  }

  .input-block {
    margin: 0.25rem 1.25rem;
    border: 1px solid $Outline;
    border-radius: 4px;
    overflow: hidden;
    width: 79rem;
    .block-header {
      background-color: $MidnightSteel;
      height: 3rem;
      @include flexbox(flex-start, center);

      h5 {
        @include label-large;
        color: $DarkOnSecondaryContainer;
        margin-left: 1.5rem;
        margin-top: 0.25rem;
        margin-bottom: 0px;
      }
    }

    .holdings-titles {
      background-color: $MidnightSteel;
      height: 28px;
      @include flexbox(flex-start, center);
      border-top: 1px solid $Outline;
      border-bottom: 1px solid $Outline;
      padding: 0rem 1rem;
      span {
        @include label-medium;
        color: $DarkOnSecondaryContainer;
        margin-top: 2px;
        width: 180px;

        &:not(:nth-child(-n + 2)) {
          text-align: center;
        }

        &:nth-child(1) {
          width: 275px;
        }

        &:nth-child(2) {
          width: 285px;
        }
      }
    }

    .holdings-data {
      &:nth-child(odd) {
        background-color: $Background1;
      }

      &:nth-child(even) {
        background-color: $Container2;
      }

      height: 48px;
      @include flexbox(flex-start, center);

      .MuiAutocomplete-root {
        @include flexbox(center, center);
        @include body-small;
        width: 280px;
        margin: 0px 11px;
        .MuiFormControl-root {
          padding: 0px;
          border: none;
          .MuiInputBase-root {
            margin: 0px;
            input {
              padding-left: 7px;
            }
          }
        }
      }
      &:focus-visible {
        border: 1px solid #4295F3;
      }

      .holding-data {
        @include body-small;
        margin-top: 2px;
        width: 180px;

        &:not(:nth-child(-n + 2)) {
          text-align: center;
        }
        &:nth-child(1) {
          width: 275px;
          margin-left: 1rem;
        }
        &:nth-child(2) {
          width: 280px;
        }
      }

      .MuiFormControl-root {
        width: 180px;

        &:nth-child(1) {
          width: 280px;
        }


        .MuiInputBase-root {
          @include body-small;
          margin: 0.75rem 1rem;
          font-weight: 500;
          height: 32px;
          border-radius: 4px;
          background-color: #fff;
        }
      }

      .add-holding-btn {
        @include text-only-button;
        height: 24px;
      }

      .MuiIconButton-root {
        margin-left: auto;
      }
      &.no-cap-data,
      &.no-holdings {
        height: 104px;
        @include flexbox(center, center, column);
        p {
          @include label-medium;
          font-weight: 400;
          &:first-child {
            @include label-medium;
          }

        }
      }
    }
  }
  .footer-container {
    @include flexbox(flex-end, center);
    background-color: $NeutralBackground;
    border-top: 1px solid #cccccc;
    height: 40px;
    margin-right: auto;
    gap: 1rem;
    .edit-btn-wrapper {
      width: 79rem;
      @include flexbox(flex-end);
      .MuiButtonBase-root {
        height: 32px;
        margin-right: 12px;
        .MuiSvgIcon-root {
          height: 20px;
          width: 20px;
        }
        &.submit-btn {
          @include blue-contained-button;
          margin-right: 0;
          &.no-pointers {
            pointer-events: none;
          }
        }
      }
    }
    .MuiButtonBase-root {
      @include text-only-button;
      font-weight: 400;
      border-radius: 0px;
      height: 39px;
      .MuiSvgIcon-root {
        width: 1.25rem;
        height: 1.25rem;
      }
      &.edit-btn {
        &.not-visible {
          display: none;
        }
      }
      &:disabled {
        color: #0061A4;
        opacity: 0.5;
      }
    }
  }
}
