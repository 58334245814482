@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.SummariesTable {
  border: 1px solid $Outline;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  @include flexbox();
  .fixed-left {
    .summaries-table-rows {
      position: absolute;
      width: 336px;
      box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);
    }
  }
  .right-scrollable {
    .loading-overlay {
      width: 976px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(240, 240, 243, 0.75);
      @include flexbox(center, center);
    }
    .summaries-table-rows {
      margin-left: 336px;
      width: 976px;
      overflow: scroll;
    }
  }
  .summaries-table-rows {
    height: 346px;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 100px;
      background-color: $DarkOutline;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-corner {
      background: rgb(0 0 0 / 0%);
    }
    .summaries-table-row-data {
      @include flexbox(flex-start, center);
      border-bottom: 1px solid $Outline;
      .table-cell {
        background-color: $Background1;
      }
      &.inactive-summaries-row {
        .table-cell {
          background-color: $LightErrorContainer;
        }
      }
      .row-data-group {
        @include flexbox;
        .table-cell {
          height: 32px;
          @include body-small;
          @include flexbox(center, center);
        }
        &.summaries-list {
          .table-cell {
            &:nth-child(1) {
              @include flexbox(flex-start, center);
              @include label-medium;
              padding-left: 24px;
              min-width: 336px;
            }
          }
        }
        &.basic-info {
          .table-cell {
            &:nth-child(1) {
              min-width: 106px;
            }
            &:nth-child(2) {
              min-width: 136px;
            }
            &:nth-child(3) {
              min-width: 144px;
            }
            &:nth-child(4) {
              min-width: 130px;
            }
            &:nth-child(5) {
              min-width: 130px;
            }
            &:nth-child(6),
            &:nth-child(7) {
              min-width: 114px;
            }
          }
        }
        &.val-volatility,
        &.tr-volatility {
          .table-cell {
            min-width: 110px;
          }
        }
        &.market-cap {
          .table-cell {
            &:nth-child(1) {
              min-width: 170px;
            }
            &:nth-child(2) {
              min-width: 190px;
            }
            &:nth-child(3) {
              min-width: 190px;
            }
          }
        }
        &.market-growth {
          .table-cell {
            min-width: 160px;
          }
        }
        &.revenue,
        &.EBITDA,
        &.gross-profit,
        &.revenue-growth,
        &.EBITDA-growth,
        &.gross-profit-growth,
        &.revenue-multiples,
        &.EBITDA-multiples,
        &.EBITDA-margin,
        &.gross-margin {
          .table-cell {
            min-width: 166px;
          }
        }
      }
    }
  }
}
